import { toast } from "react-toastify";
import { YieldSheetService } from "../services";

const downloadFile = async function (
  hotelId: number,
  id: string,
  name: string,
) {
  try {
    const res = await YieldSheetService.revanista.files.getDownloadLink(
      hotelId,
      id,
      name,
    );
    const link = document.createElement("a");
    link.href = res.url;
    link.setAttribute("download", id);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    toast.error("Download failed");
    console.log(err);
  }
};
const deleteFile = function (hotelId: number, id: string) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await YieldSheetService.revanista.files.delete(hotelId, id, name);
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export default {
  downloadFile,
  deleteFile,
};
