import { createTheme } from "@mui/material";

// Define spacing constants
const spacing = {
  xs: "4px", // 0.5x base unit
  sm: "8px", // 1x base unit
  md: "16px", // 2x base unit
  lg: "24px", // 3x base unit
  xl: "32px", // 4x base unit
};

// Define dimension constants
const dimensions = {
  textField: {
    width: 350,
  },
  card: {
    small: {
      height: 50,
      width: "25%",
    },
    medium: {
      height: 70,
      width: "30%",
    },
    large: {
      height: 80,
      width: "95%",
    },
  },
  drawer: {
    width: 240,
  },
  modal: {
    width: 400,
  },
};

// Define typography constants
const typography = {
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontSize: "2.5rem",
    fontWeight: 500,
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  h3: {
    fontSize: "1.75rem",
    fontWeight: 500,
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  body1: {
    fontSize: "1rem",
  },
  body2: {
    fontSize: "0.875rem",
  },
};

// Define color palette
const palette = {
  primary: {
    main: "#ce0e29",
    light: "#f44336",
    dark: "#b71c1c",
    contrastText: "#fff",
  },
  secondary: {
    main: "#9c27b0",
    light: "#ba68c8",
    dark: "#7b1fa2",
    contrastText: "#fff",
  },
  error: {
    main: "#d32f2f",
    light: "#ef5350",
    dark: "#c62828",
    contrastText: "#fff",
  },
  warning: {
    main: "#ed6c02",
    light: "#ff9800",
    dark: "#e65100",
    contrastText: "#fff",
  },
  info: {
    main: "#0288d1",
    light: "#03a9f4",
    dark: "#01579b",
    contrastText: "#fff",
  },
  success: {
    main: "#2e7d32",
    light: "#4caf50",
    dark: "#1b5e20",
    contrastText: "#fff",
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#f5f5f5",
    A200: "#eeeeee",
    A400: "#bdbdbd",
    A700: "#616161",
  },
};

export const baseTheme = createTheme({
  typography,
  palette,
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: spacing.md,
          paddingRight: spacing.md,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: `${spacing.sm} ${spacing.md}`,
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: dimensions.textField.width,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: spacing.md,
          "&:last-child": {
            paddingBottom: spacing.md,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: dimensions.drawer.width,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            padding: "2px 8px",
          },
          "& .MuiOutlinedInput-root": {
            padding: "6px 12px",
          },
        },
        paper: {
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          borderRadius: "4px",
        },
        option: {
          padding: "10px 16px",
          "&:hover": {
            backgroundColor: "rgba(206, 14, 41, 0.08)",
          },
          "&[aria-selected='true']": {
            backgroundColor: "rgba(206, 14, 41, 0.12)",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: spacing.sm,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: spacing.sm,
        },
      },
    },
  },
});
