import {
  Box,
  Button,
  TextField,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Typography,
  FormControl,
} from "@mui/material";
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BulkOwsOverridePayload,
  BulkUpdatePayload,
  Dow,
  IBulkManualRateUpdate,
  IBulkRewardsUpdate,
  IManualRateUpdate,
  IRestrictionsUpdate,
} from "../../models/api.models";
import { YieldSheetService } from "../services";
import { BulkUpdatePreview } from "./bulk-update-preview";
import { restrictions } from "gn-shared";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Popup from "../../reusable-components/popup/popup";
import "./bulk-update.css";
import { getFieldClasses } from "../common/forms";
import { styled } from "@mui/material/styles";
import { YieldApi } from "api/yield";
import { connect } from "react-redux";
import { appStore } from "App/store";

interface PublishPayload {
  start: string;
  end: string;
  baseRate: number;
  weekDays: boolean[];
  owslabel: string;
}

interface Period {
  start: string;
  end: string;
  idx: number;
}

interface FormState extends PublishPayload {
  property:
    | "baseRate"
    | "owsBaseRate"
    | "setRate"
    | "APPLY_STOP_SELL"
    | "REMOVE_STOP_SELL"
    | "APPLY_CLOSED_TO_ARRIVAL"
    | "REMOVE_CLOSED_TO_ARRIVAL"
    | "APPLY_CLOSED_TO_DEPARTURE"
    | "REMOVE_CLOSED_TO_DEPARTURE"
    | "APPLY_MIN_DAYS#2"
    | "REMOVE_MIN_DAYS"
    | "OPEN_REWARDS"
    | "CLOSE_REWARDS"
    | "OVERIDE_OWS";
  allDayCheked: boolean;
}

export interface State {
  toYieldSheet: boolean;
  displayWarning: boolean;
  displaySpinner: boolean;
  restrictionSelections: any;
  allDaySelected: boolean;
  periods: Period[];
  defaultOwsLabel: string;
  popupStatus: boolean;
  weekDays: boolean[];
  property: string;
  baseRate: number;
  errors: { [key: string]: string };
  owslabel: string;
  touched: { [key: string]: boolean };
  navigateToYieldSheet: boolean;
  level: string;
}

interface StateProps {
  activeHotel: any;
}

interface Props extends StateProps, bulkUpdateProps {}

interface bulkUpdateProps {
  isPopupOpen: boolean;
  popupState: (state: boolean) => void;
}

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const DatePickerWrapper = styled("div")({
  position: "relative",
  "& .MuiInputLabel-root": {
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0 4px",
    top: "-8px",
    left: "5rem",
    fontSize: "0.75rem",
    zIndex: 1,
    color: "rgba(0, 0, 0, 0.6)",
  },
  "& .MuiInputLabel-root.to-label": {
    left: "2.5rem",
  },
});

class BulkUpdate extends Component<Props, State> {
  readonly state: State = {
    toYieldSheet: false,
    displayWarning: false,
    displaySpinner: false,
    restrictionSelections: {},
    allDaySelected: true,
    defaultOwsLabel: "Default",
    popupStatus: false,

    periods: [
      {
        idx: 0,
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    ],
    weekDays: [true, true, true, true, true, true, true],
    property: "baseRate",
    baseRate: 999,
    errors: {},
    owslabel: "Default",
    touched: {},
    navigateToYieldSheet: false,
    level: "baseRate",
  };

  constructor(props: Props) {
    super(props);

    this.state.baseRate = this.props.activeHotel.meta.defaultBar;
    this.applyToYieldSheet = this.applyToYieldSheet.bind(this);

    if (
      typeof this.props.activeHotel.cm.channelConfig.ows.label !== "undefined"
    ) {
      this.state.defaultOwsLabel =
        this.props.activeHotel.cm.channelConfig.ows.label;
    }
    this.state.popupStatus = this.props.isPopupOpen;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.activeHotel !== this.props.activeHotel) {
      this.setState({ baseRate: this.props.activeHotel.meta.defaultBar });
      this.applyToYieldSheet = this.applyToYieldSheet.bind(this);

      if (
        typeof this.props.activeHotel.cm.channelConfig.ows.label !== "undefined"
      ) {
        this.setState({
          defaultOwsLabel: this.props.activeHotel.cm.channelConfig.ows.label,
        });
      }
      this.setState({ popupStatus: this.props.isPopupOpen });
    }
  }

  addPeriod = () => {
    const periods = this.state.periods;
    periods.push({
      idx: periods.length,
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    });
    this.setState({ periods });
  };

  removePeriod = () => {
    const periods = this.state.periods;
    periods.pop();
    this.setState({ periods });
  };

  applyToYieldSheet(data: any) {
    this.setState({ navigateToYieldSheet: true });
    if (data.baseRate <= 0) {
      toast("Please check the highlited field", { type: "error" });
      return;
    } else {
      const formDataValues = data;
      this.setState({ displaySpinner: true });
      let payloads;
      let promises;
      switch (formDataValues.property) {
        case "OVERIDE_OWS":
          payloads = this.prepareOverideOwsPayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldApi.bulkUpdateOwsOverride(payload),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating OWS overrides");
            });
          break;
        case "baseRate":
          payloads = this.prepareBaseRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldApi.bulkUpdateRates(payload),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "owsBaseRate":
          console.log("hp");
          payloads = this.prepareOWSBaseRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldApi.bulkUpdateRates(payload),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "setRate":
          payloads = this.prepareSetRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateManualRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "OPEN_REWARDS":
        case "CLOSE_REWARDS":
          payloads = this.prepareRewardsPayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldApi.bulkUpdateRewards(payload),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating restrictions");
            });

          break;
        default:
          this.prepareRestrictionsPayload(formDataValues).then((payloads) => {
            promises = payloads.map((payload) =>
              YieldApi.bulkUpdateRestrictions(payload),
            );
            Promise.all(promises)
              .then(() => {
                this.setState({ displayWarning: true, displaySpinner: false });
              })
              .catch((err: any) => {
                console.error(err);
                this.setState({ displaySpinner: false });
                toast("Error while updating restrictions", { type: "error" });
              });
          });
      }
    }
  }

  discardAndExit() {
    this.props.popupState(false);
  }

  handleInputChange = (event: any) => {
    const { value } = event.target;
    this.setState({ baseRate: value });
    this.validate(value);
  };

  setStartDate = (date: Date | null, id: any) => {
    if (date) {
      this.setState((prevState) => {
        const periods = prevState.periods.map((period) => {
          if (period.idx === id.idx) {
            const newStartDate = moment(date).format("YYYY-MM-DD");
            // If the new start date is after the current end date, update the end date
            const currentEndDate = moment(period.end);
            if (moment(date).isAfter(currentEndDate)) {
              return {
                ...period,
                start: newStartDate,
                end: newStartDate,
              };
            }
            return { ...period, start: newStartDate };
          }
          return period;
        });
        return { periods };
      });
    }
  };

  handleWeekDayChange = (index: any) => {
    this.setState((prevState) => {
      const newWeekDays = [...prevState.weekDays];
      newWeekDays[index] = !newWeekDays[index];
      return { weekDays: newWeekDays };
    });
  };

  setEndDate = (date: Date | null, id: any) => {
    if (date) {
      this.setState((prevState) => ({
        periods: prevState.periods.map((period) => {
          if (period.idx === id.idx) {
            const newEndDate = moment(date).format("YYYY-MM-DD");
            return { ...period, end: newEndDate };
          }
          return period;
        }),
      }));
    }
  };

  validate = (value: any) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (value <= 0) {
      error = "Must be positive";
    }
    return error;
  };

  handleNativeSelect = (event: any) => {
    const rateLabel = event.target.value;
    getRateLabel(rateLabel);
    this.setState({ property: rateLabel });
    this.setState({ level: event.target.value });
  };

  render() {
    if (this.state.toYieldSheet) {
      return <Navigate to={`/${this.props.activeHotel.hotelId}`} />;
    }

    let owsOverrideOptions: any = null;

    if (
      this.props.activeHotel?.cm?.channelConfig?.ows?.SeasonnalConfig?.formulas
    ) {
      owsOverrideOptions =
        this.props.activeHotel.cm.channelConfig.ows.SeasonnalConfig.formulas.map(
          (item: any) => (
            <option key={item.label} value={item.label}>
              {item.label}
            </option>
          ),
        );
    }

    return (
      <>
        <Popup
          isPopupOpen={this.state.displayWarning}
          title="Applying changes to Yield Sheet..."
          content={[
            "Your changes will be processed in the background, it may take a moment to be reflected on the yield sheet.",
          ]}
          action={() => {
            this.setState({ displayWarning: false });
          }}
          actionName="Back to Bulk Update"
          cancelAction={() => {
            this.setState({ displayWarning: false });
            this.props.popupState(false);
          }}
          cancelName="Back to yield sheet"
          maxWidth="sm"
        />
        <Popup
          isPopupOpen={this.props.isPopupOpen}
          closeOnOutsideClick={true}
          title="Bulk Update"
          content={[
            <Box
              component="div"
              sx={{
                padding: 2,
                width: "100%",
                height: "350px",
              }}
              key="bulk-update-content"
            >
              <Box className="row" sx={{ paddingRight: "1rem" }}>
                <Box>
                  {this.state.periods.map((period: Period) => (
                    <Box
                      key={period.idx}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "1rem",
                          marginRight: "3rem",
                          width: "100%",
                        }}
                      >
                        <DatePickerWrapper>
                          <InputLabel
                            className="to-label"
                            sx={{ paddingLeft: "1rem" }}
                          >
                            From
                          </InputLabel>
                          <DatePicker
                            selected={moment(period.start).toDate()}
                            onChange={(date: Date | null) => {
                              this.setStartDate(date, period);
                            }}
                            dateFormat="dd/MM/yyyy"
                            className="dateStartandEnd to-date"
                            minDate={new Date()}
                            popperPlacement="bottom"
                          />
                        </DatePickerWrapper>
                        <DatePickerWrapper>
                          <InputLabel
                            className="to-label"
                            sx={{ paddingLeft: "1rem" }}
                          >
                            To
                          </InputLabel>
                          <DatePicker
                            selected={moment(period.end).toDate()}
                            onChange={(date: Date | null) =>
                              this.setEndDate(date, period)
                            }
                            dateFormat="dd/MM/yyyy"
                            className="dateStartandEnd to-date"
                            minDate={moment(period.start).toDate()}
                            popperPlacement="bottom"
                          />
                        </DatePickerWrapper>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ paddingRight: "1rem" }}>
                <Box
                  className="form-group row"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "1rem",
                      marginLeft: "3rem",
                    }}
                  >
                    On
                  </Typography>
                  {days.map((day, dayIdx) => (
                    <FormControlLabel
                      key={day}
                      control={
                        <Checkbox
                          checked={this.state.weekDays[dayIdx]}
                          onChange={() => this.handleWeekDayChange(dayIdx)}
                          name={day}
                          color="primary"
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
                        />
                      }
                      label={day}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                className="row align-items-center"
                display="flex"
                alignItems="center"
              >
                <Box
                  className="col col-sm-3"
                  marginLeft="3rem"
                  marginRight="5rem"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <Button onClick={this.addPeriod}>
                    <AddIcon />
                  </Button>
                  {this.state.periods.length > 1 && (
                    <Button onClick={this.removePeriod}>
                      <RemoveIcon />
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                className="row align-items-center"
                sx={{
                  marginTop: "1rem",
                  marginLeft: "2rem",
                  marginRight: "4rem",
                }}
              >
                <Box>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="form-group"
                  >
                    <InputLabel>Update</InputLabel>
                    <Select
                      className="UpdateClass"
                      id="property-label"
                      style={{
                        width: "3rem !important",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      label="Update"
                      variant="outlined"
                      name="level"
                      value={this.state.level}
                      onChange={(e) => this.handleNativeSelect(e)}
                    >
                      <MenuItem value="baseRate">Base Rate</MenuItem>
                      {hasOWSBRRow(this.props.activeHotel) && (
                        <MenuItem value="owsBaseRate">OWS Base Rate</MenuItem>
                      )}

                      <MenuItem value="OVERIDE_OWS">OWS Override</MenuItem>

                      <MenuItem value="setRate">Set Rate</MenuItem>
                      <MenuItem value="APPLY_STOP_SELL">+ Stop Sell</MenuItem>
                      <MenuItem value="REMOVE_STOP_SELL">- Stop Sell</MenuItem>
                      <MenuItem value="APPLY_MIN_DAYS#2">+ Min LOS 2</MenuItem>
                      <MenuItem value="REMOVE_MIN_DAYS">- Min LOS</MenuItem>
                      <MenuItem value="APPLY_CLOSED_TO_ARRIVAL">
                        + Closed To Arrival
                      </MenuItem>
                      <MenuItem value="REMOVE_CLOSED_TO_ARRIVAL">
                        - Closed To Arrival
                      </MenuItem>
                      <MenuItem value="APPLY_CLOSED_TO_DEPARTURE">
                        + Closed To Departure
                      </MenuItem>
                      <MenuItem value="REMOVE_CLOSED_TO_DEPARTURE">
                        - Closed To Departure
                      </MenuItem>

                      {this.props.activeHotel.meta.hasRewards && (
                        <MenuItem key="CLOSE_REWARDS" value="CLOSE_REWARDS">
                          Close Rewards
                        </MenuItem>
                      )}

                      {this.props.activeHotel.meta.hasRewards && (
                        <MenuItem key="OPEN_REWARDS" value="OPEN_REWARDS">
                          Open Rewards
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display:
                      this.state.property === "baseRate" ||
                      this.state.property === "setRate" ||
                      this.state.property === "owsBaseRate"
                        ? "block"
                        : "none",
                  }}
                  className="col col-sm-3"
                >
                  <div className="bulkUpdateTop">
                    <TextField
                      id="baseRate"
                      name="baseRate"
                      label={getRateLabel(this.state.property)}
                      variant="outlined"
                      style={{
                        width: "3rem !important",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "transparent",
                      }}
                      type="number"
                      placeholder=""
                      value={this.state.baseRate}
                      InputProps={{ inputProps: { step: 1 } }}
                      onChange={(e) => this.handleInputChange(e)}
                      className={getFieldClasses(this.state, "baseRate")}
                      error={!!this.state.errors.baseRate}
                      helperText={this.state.errors.baseRate || ""}
                    />
                  </div>
                  <div className="bulkValidation">
                    {this.state.errors.baseRate}
                  </div>
                </Box>
                <Box className="col col-sm-3">
                  <div hidden={this.state.property !== "OVERIDE_OWS"}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className="form-group"
                    >
                      <InputLabel>Label</InputLabel>
                      <Select
                        id="property-label"
                        style={{
                          width: "3rem !important",
                          backgroundColor:
                            appStore.meta._user.permissions.admin[0] !== "*"
                              ? "#D3D3D3"
                              : "transparent",
                        }}
                        fullWidth
                        name="owslabel"
                        label="Label"
                        variant="outlined"
                        value={this.state.owslabel}
                        onChange={(e) =>
                          this.setState({ owslabel: e.target.value })
                        }
                      >
                        <MenuItem value={this.state.defaultOwsLabel}>
                          {this.state.defaultOwsLabel}
                        </MenuItem>

                        {owsOverrideOptions && Array.isArray(owsOverrideOptions)
                          ? owsOverrideOptions.map((option, index) => (
                              <MenuItem key={index} value={option.key}>
                                {option.key}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </Box>
              </Box>
              <Box>
                <BulkUpdatePreview
                  hotelId={this.props.activeHotel.hotelId}
                  rate={
                    !(
                      this.state.property === "baseRate" ||
                      this.state.property === "owsBaseRate" ||
                      this.state.property === "CLOSE_REWARDS" ||
                      this.state.property === "OPEN_REWARDS" ||
                      this.state.property === "OVERIDE_OWS"
                    )
                      ? 100
                      : this.state.baseRate
                  }
                  selectable={
                    this.state.property !== "baseRate" &&
                    this.state.property !== "owsBaseRate" &&
                    this.state.property !== "CLOSE_REWARDS" &&
                    this.state.property !== "OPEN_REWARDS" &&
                    this.state.property !== "OVERIDE_OWS"
                  }
                  manualOnly={this.state.property === "setRate"}
                  onSelection={(selections) =>
                    this.setState({ restrictionSelections: selections })
                  }
                  appStore={this.props.activeHotel}
                />
              </Box>
            </Box>,
          ]}
          maxWidth="md"
          action={() => {
            const formData = {
              property: this.state.property,
              baseRate: this.state.baseRate,
              weekDays: this.state.weekDays,
              owslabel: this.state.owslabel,
            };
            this.applyToYieldSheet(formData);
          }}
          actionName="Apply"
          cancelAction={() => this.props.popupState(false)}
          cancelName="Back to yield sheet"
        />
      </>
    );
  }

  private prepareRewardsPayload(data: FormState): IBulkRewardsUpdate[] {
    const hotelId = this.props.activeHotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    const res = this.state.periods.map((p) => {
      return {
        start: p.start,
        end: p.end,
        hotelId,
        dow,
        active: data.property.indexOf("OPEN") !== -1,
      };
    });
    return res;
  }
  private prepareOverideOwsPayload(
    data: PublishPayload,
  ): BulkOwsOverridePayload[] {
    const hotelId = this.props.activeHotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    const res = this.state.periods.map((p) => {
      return {
        ...data,
        start: p.start,
        end: p.end,
        baseRate: data.baseRate,
        dow,
        hotelId,
        label: data.owslabel,
      };
    });
    return res;
  }
  private prepareBaseRatePayload(data: PublishPayload): BulkUpdatePayload[] {
    const hotelId = this.props.activeHotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    const res = this.state.periods.map((p) => {
      return {
        ...data,
        start: p.start,
        end: p.end,
        baseRate: data.baseRate,
        dow,
        hotelId,
      };
    });
    return res;
  }
  private prepareOWSBaseRatePayload(data: PublishPayload): BulkUpdatePayload[] {
    const hotelId = this.props.activeHotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    const res = this.state.periods.map((p) => {
      return {
        weekDays: data.weekDays,
        baseRate: 0,
        start: p.start,
        end: p.end,
        owsBaseRate: data.baseRate,
        dow,
        hotelId,
      };
    });
    return res;
  }
  private prepareSetRatePayload(data: FormState): IBulkManualRateUpdate[] {
    const hotel = this.props.activeHotel;
    const hotelId = hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    const results = this.state.periods.map((p) => {
      const v: IManualRateUpdate[] = [];
      return {
        dow,
        updates: v,
        hotelId,
        start: p.start,
        end: p.end,
      };
    });

    console.log(this.state.restrictionSelections);
    results.forEach((res) => {
      Object.keys(this.state.restrictionSelections)
        .map((roomtype) => {
          const i: IManualRateUpdate[] = [];
          const rps = Object.keys(
            this.state.restrictionSelections[roomtype].ratePlans,
          );
          rps.forEach((rp: string) => {
            i.push({
              hotelId,
              start: res.start,
              end: res.end,
              rate: data.baseRate * 100,
              invTypeCode: roomtype,
              ratePlanID: rp,
            });
          });
          return i;
        })
        .forEach((value) => {
          res.updates.push(...value);
        });
    });
    return results;
  }

  private prepareRestrictionsPayload(
    data: FormState,
  ): Promise<IRestrictionsUpdate[]> {
    return new Promise((resolve) => {
      const hotel = this.props.activeHotel;
      const hotelId = hotel.hotelId;
      const actionName: "apply" | "remove" = !data.property.indexOf("APPLY_")
        ? "apply"
        : "remove";
      const daycount = data.property.indexOf("#")
        ? data.property.split("#")[1]
        : 0;
      const restrictionKey = data.property
        .replace(/^[A-Z]*_/, "")
        .split("#")[0] as RestrictionTypeKeys;
      const restriction: RestrictionToApply = {
        type: restrictions.TYPE[restrictionKey],
        dayCount: Number(daycount),
      };
      const dow: Dow = days.reduce(
        (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
        {},
      );
      const makeDayObj = (roomType: string) => ({
        restrictions: {
          [roomType]: {
            ratePlans: Object.keys(hotel.ratePlans).reduce(
              (acc, ratePlan) => ({ ...acc, [ratePlan]: {} }),
              {},
            ),
          },
        },
      });
      const roomTypeRestrictions = Object.keys(this.state.restrictionSelections)
        .filter(
          (roomType) => this.state.restrictionSelections[roomType].selected,
        )
        .map(async (roomType) => {
          let obj = makeDayObj(roomType);
          if (actionName === "apply") {
            obj = await restrictions.apply(obj, { ...restriction, roomType });
          } else {
            obj = await restrictions.remove(hotel.meta.globalRestriction, obj, {
              ...restriction,
              roomType,
            });
          }

          return obj;
        });
      const ratePlanRestrictions = Object.keys(this.state.restrictionSelections)
        .filter((roomType) => {
          const roomTypeData = this.state.restrictionSelections[roomType];
          return (
            !roomTypeData.selected &&
            Object.keys(roomTypeData.ratePlans).some(
              (ratePlan) => roomTypeData.ratePlans[ratePlan],
            )
          );
        })
        .map(async (roomType) => {
          let obj = makeDayObj(roomType);
          const roomTypeData = this.state.restrictionSelections[roomType];
          const rps = Object.keys(roomTypeData.ratePlans)
            .filter((ratePlan) => roomTypeData.ratePlans[ratePlan])
            .filter(
              (ratePlan) =>
                !hotel.ratePlans[ratePlan].restrictionExclusions ||
                hotel.ratePlans[ratePlan].restrictionExclusions.indexOf(
                  restriction.type,
                ) === -1,
            );
          for (const ratePlan of rps) {
            if (actionName === "apply") {
              obj = await restrictions.apply(obj, {
                ...restriction,
                roomType,
                ratePlan,
              });
            } else {
              obj = await restrictions.remove(
                hotel.meta.globalRestriction,
                obj,
                { ...restriction, roomType, ratePlan },
              );
            }
          }
          return obj;
        });

      Promise.all(ratePlanRestrictions).then((v) => {
        Promise.all(roomTypeRestrictions).then((w) => {
          const allRoomTypeRestrictions = Object.assign(
            {},
            ...[...v, ...w].map((o) => o.restrictions),
          );
          const result = this.state.periods.map((p) => {
            return {
              start: p.start,
              end: p.end,
              restrictions: allRoomTypeRestrictions,
              dow,
              hotelId,
            };
          });

          resolve(result);
        });
      });
    });
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
});

export default connect(mapStateToProps)(BulkUpdate);

function hasOWSBRRow(hotel: any) {
  return hotel.cm.channelConfig && hotel.cm.channelConfig.ows;
}

export function getRateLabel(input: string) {
  switch (input) {
    case "baseRate":
      return "Base Rate";
    case "owsBaseRate":
      return "OWS Base Rate";
    default:
      return "Set Rate";
  }
}
