import React, { Component, Fragment } from "react";
import {
  Container,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  ListItem,
  Divider,
  TextField,
} from "@mui/material";
import { YieldSheetService } from "../../services";

import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import moment from "moment";
import commonfileUtils from "../common";
import { toast } from "react-toastify";
import withRouter from "../../withRouter";

interface State {
  item: any;
  avatarColors: { [key: string]: string };
  isEditMode: boolean;
  editedText: string;
  editedAction: string;
  editedAttachments: any[];
  isEdited: boolean;
}

export interface IExecSummaryItemProps {
  item: any;
  avatarColors: any;
  hotelId: number;
  router: any;
}

class ExecSummaryItem extends Component<IExecSummaryItemProps> {
  readonly state: State = {
    item: {},
    avatarColors: {},
    isEditMode: false,
    editedText: "",
    editedAction: "",
    editedAttachments: [],
    isEdited: false,
  };
  hotelId: number;
  constructor(props: IExecSummaryItemProps) {
    super(props);
    this.state.item = props.item;
    this.state.avatarColors = props.avatarColors;
    this.hotelId = props.hotelId;
  }

  componentDidUpdate(prevProps: IExecSummaryItemProps, prevState: State) {
    const { editedAttachments } = this.state;

    if (prevState.editedAttachments !== editedAttachments) {
      console.log("attatatatats", editedAttachments.length);

      if (editedAttachments.length === this.state.item.attachments.length) {
        this.setState({ isEdited: false });
      }
    }
  }

  toggleEditItem = () => {
    this.setState({ isEdited: false });
    this.setState({
      editedAction: this.state.item.action,
      editedAttachments: this.state.item.attachments,
      editedText: this.state.item.description,
      isEditMode: !this.state.isEditMode,
    });
  };

  handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editedText: e.target.value });

    const { item } = this.state;
    if (item.description === e.target.value) {
      this.setState({ isEdited: false });
    }
    if (item.description !== e.target.value) {
      this.setState({ isEdited: true });
    }
  };
  handleActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editedAction: e.target.value });

    const { item } = this.state;
    if (item.action === e.target.value) {
      this.setState({ isEdited: false });
    }
    if (item.action !== e.target.value) {
      this.setState({ isEdited: true });
    }
  };

  appendFile = (e: any) => {
    const a = this.state.editedAttachments;
    const p: Promise<{ name: string; id: string }>[] = [];
    for (const file of e.target.files) {
      const tokens = file.name.split(".");
      p.push(
        YieldSheetService.revanista.files
          .getUploadLink(
            this.hotelId,
            file.type,
            tokens[tokens.length - 1],
            file.name,
          )
          .then((res: { presignedUrl: string; objectKey: string }) => {
            const url = res.presignedUrl;
            const filename = res.objectKey;
            const reader = new FileReader();
            reader.onload = function (e: any) {
              const blob = new Blob([new Uint8Array(e.target.result)], {
                type: file.type,
              });
              YieldSheetService.revanista.files
                .upload(url, file.type, blob)
                .catch((err: Error) => {
                  toast.error("Error uploading " + file.name);
                  console.log(err);
                });
            };
            reader.readAsArrayBuffer(file);
            return { name: file.name.replace(/^.*[\\/]/, ""), id: filename };
          }),
      );
    }
    Promise.all(p).then((v: { name: string; id: string }[]) => {
      if (v !== undefined) {
        a.push(...v);
      }

      console.log(a);

      if (a.length > this.state.item.attachments.length) {
        this.setState({ isEdited: true });
      }

      this.setState({ editedAttachments: a });
    });
  };

  deletefile = (id: string, index: number) => {
    commonfileUtils
      .deleteFile(this.hotelId, id)
      .then(() => {
        const e = JSON.parse(JSON.stringify(this.state.editedAttachments));
        e.splice(index, 1);
        this.setState({ editedAttachments: e });
        this.forceUpdate();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error deleting file");
      });
    //removed it from the list!
  };

  saveItem = () => {
    this.setState({ isEdited: false });
    let t = this.state.editedText;
    const a = this.state.editedAction;
    const item: {
      origin: string;
      hotelId: number;
      timestamp: string;
      description?: string;
      action?: string;
      attachments: { name: string; id: string }[];
    } = {
      origin: this.state.item.origin,
      hotelId: this.hotelId,
      timestamp: this.state.item.timestamp,
      description: t,
      action: a,
      attachments: this.state.editedAttachments,
    };

    if (typeof t == "undefined") {
      t = "";
    }

    if (t.length === 0) {
      delete item.description;
    }
    if (a && a.length === 0) {
      delete item.action;
    }
    YieldSheetService.revanista.hotel.putExecSummaryItem(item).then(() => {
      this.setState({ item, isEditMode: !this.state.isEditMode });
    });
  };

  render() {
    const item = this.state.item;
    const avatarColor = this.state.avatarColors;
    const isEditMode = this.state.isEditMode;
    const attachements = isEditMode
      ? this.state.editedAttachments
      : item.attachments;

    return (
      <Container style={{ whiteSpace: "pre-wrap" }}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar style={{ margin: "auto" }}>
            <Avatar
              alt={item.origin}
              src="./icon/trololo.png"
              style={{ backgroundColor: avatarColor[item.origin] }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <React.Fragment>
                <span style={{ color: "crimson", fontWeight: "bold" }}>
                  {item.origin +
                    " - " +
                    moment(item.timestamp).format("DD/MM/YYYY")}
                </span>

                {!isEditMode && (
                  <Button
                    onClick={() => {
                      this.toggleEditItem();
                    }}
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ float: "right" }}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                )}
                {isEditMode && (
                  <span style={{ float: "right" }}>
                    <label htmlFor="contained-button-file">
                      <Button
                        color="primary"
                        component="span"
                        id="execItemAttachIcon"
                      >
                        <AttachFileIcon />
                      </Button>
                    </label>
                    <Button
                      aria-label="cancel"
                      onClick={this.toggleEditItem}
                      color="secondary"
                      id="execItemDenyIcon"
                      variant="contained"
                      style={{
                        backgroundColor: "#808080",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      aria-label="create"
                      onClick={this.saveItem}
                      color="primary"
                      id="execItemAcceptIcon"
                      variant="contained"
                      style={{
                        backgroundColor: "#ce0e29",
                        color: "#ffffff",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Save
                    </Button>
                    <input
                      data-fileindex={this.state.editedAttachments.length}
                      accept="*/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={this.appendFile}
                    />
                  </span>
                )}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <br />
                {!isEditMode && (
                  <div
                    style={{
                      width: "49%",
                      float: "left",
                      opacity: 0.8,
                      borderRight:
                        item.action && item.action.trim().length > 0
                          ? "1px solid black"
                          : "",
                    }}
                  >
                    {item.description}
                  </div>
                )}

                {isEditMode && (
                  <div style={{ width: "45%", float: "left" }}>
                    {" "}
                    <TextField
                      id="outlined-textarea"
                      label="Description"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "inline-grid",
                      }}
                      value={this.state.editedText}
                      onChange={this.handleDescChange}
                      multiline
                      variant="outlined"
                      name="execItemDescription"
                    />
                  </div>
                )}
                {!isEditMode && item.action && item.action.length > 0 && (
                  <Fragment>
                    <div style={{ width: "49%", float: "right", opacity: 0.8 }}>
                      {item.action}
                    </div>
                  </Fragment>
                )}
                {isEditMode && (
                  <div style={{ width: "45%", float: "right" }}>
                    {" "}
                    <TextField
                      id="outlined-textarea"
                      label="Action"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "inline-grid",
                      }}
                      value={this.state.editedAction}
                      onChange={this.handleActionChange}
                      //placeholder=""
                      //inputRef={this.editTextBoxref}
                      multiline
                      variant="outlined"
                      name="execItemAction"
                    />
                  </div>
                )}
                <br />
                <div style={{ display: "inline-block", width: "100%" }}>
                  {attachements.length > 0 ? <br /> : ""}
                  {attachements.map(
                    (item: { name: string; id: string }, index: number) => {
                      return (
                        <Button
                          onClick={() => {
                            !isEditMode &&
                              commonfileUtils.downloadFile(
                                this.hotelId,
                                item.id,
                                item.name,
                              );
                          }}
                          variant="contained"
                          color="primary"
                          // className="message_ticker"
                          style={{
                            marginLeft: index > 0 ? "20px" : "0px",
                            margin: "10px",
                            // minWidth: 300,
                            // overflow: "hidden",
                          }}
                          startIcon={!isEditMode && <ArrowDownwardIcon />}
                          endIcon={
                            isEditMode && (
                              <DeleteIcon
                                style={{ color: "red" }}
                                onClick={() => {
                                  this.deletefile(item.id, index);
                                }}
                              />
                            )
                          }
                        >
                          <div style={{ width: "minContent" }}>{item.name}</div>
                        </Button>
                      );
                    },
                  )}
                </div>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </Container>
    );
  }
}
export default withRouter(ExecSummaryItem);
