import React, { Component, Fragment } from "react";
import SectionTitle from "../../elements/sectionTitle";
import { YieldApi } from "../../../api/yield";
import "./HotelConfigStyles.css";
import CircularProgress from "@mui/material/CircularProgress";
import { MuiThemeProvider } from "@material-ui/core";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { State, rtTheme } from "./defs";
import { toast } from "react-toastify";
import moment from "moment";
import FormulaOverides from "./formulaOverides";
import { appStore } from "../../store";
import withRouter from "../../withRouter";
import Popup from "../../../reusable-components/popup/popup";
import TimezoneSelect from "react-timezone-select";
import { connect } from "react-redux";
import { setActiveHotel } from "redux/Slice/hotelSlice";

interface Props {
  activeHotel: any;
  router: any;
  setActiveHotel: any;
}

interface Formula {
  label: string;
  formula: string;
  start: string;
  end: string;
  order?: number;
}

class NewHotelConfig extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log("Constructor props:", props);

    // Initialize with activeHotel if available
    const hotelData = props.activeHotel;
    this.state = {
      hotel: hotelData
        ? JSON.parse(JSON.stringify(hotelData))
        : {
            fullName: "",
            name: "",
            userName: "NO DATA",
            password: "NO DATA",
            meta: {
              otaPickupRange: 0,
              pickupRange: 0,
              isSyncOn: false,
              hasRewards: true,
              isPublishingInventory: false,
            },
            cm: {
              id: "",
              APISettings: {
                OWSflexTable: "",
                flexTable: "",
              },
              channelConfig: {
                ows: {
                  label: "",
                },
              },
            },
            pms: {
              id: "none",
              inventoryType: "manual",
              apiSettings: {
                hotelCode: "",
                urlEndpoint: "",
              },
            },
          },
      deleteFormula: {
        hotelId: 0,
        start: "",
        end: "",
        label: "",
        deleteLabel: "",
      },
      currentSetIndex: 1,
      displayUpdateRateWarningOnsave: false,
      warningDialogOpened: false,
      validateFullName: false,
      validateFullNameMsg: "",
      validateFormula: false,
      validateFormulaMsg: "",
      validateName: false,
      validateNameMsg: "",
      validateContractType: false,
      validateContractTypeMsg: "",
      validateTotalRoom: false,
      validateTotalRoomMsg: "",
      validateInventory: false,
      validateInventoryMsg: "",
      validateCmHotelCode: false,
      validateCmHotelCodeMsg: "",
      validatePmsHotelCode: false,
      validatePmsHotelCodeMsg: "",
      validateProviderKey: false,
      validateProviderKeyMsg: "",
      validatePmsUrlEndpoint: false,
      validatePmsUrlEndpointMsg: "",
      validateUserName: false,
      validateUserNameMsg: "",
      validateFelexTable: false,
      validateFelexTableMsg: "",
      validateOwsFelexTable: false,
      validateOwsFelexTableMsg: "",
      validateDefaultBar: false,
      validateDefaultBarMsg: "",
      validateMinRate: false,
      validateMinRateMsg: "",
      validateMaxRate: false,
      validateMaxRateMsg: "",
      validatePickupRange: false,
      validatePickupRangeMsg: "",
      validateOtaPickupRange: false,
      validateOtaPickupRangeMsg: "",
      setGuestline: false,
      onSave: false,
      hover: false,
      disableOTAPickup: false,
      isSyncOn: hotelData?.meta?.isSyncOn || false,
      openRewardsPopup: false,
      rewardsPopupToBeOpen: false,
      originalHasRewardsValue: hotelData?.meta?.hasRewards || true,
    };
  }

  async componentDidMount() {
    console.log("ComponentDidMount - activeHotel:", this.props.activeHotel);
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    const hotelId = parseInt(parts[1]);

    // If we have a valid hotel ID and activeHotel doesn't match
    if (
      !isNaN(hotelId) &&
      (!this.props.activeHotel || this.props.activeHotel.hotelId !== hotelId)
    ) {
      console.log("Fetching hotel data for ID:", hotelId);
      // Fetch hotel data
      try {
        const hotelData = await YieldApi.getHotelConfig(hotelId);
        console.log("Fetched hotel data:", hotelData);
        if (hotelData) {
          // Update Redux store
          this.props.setActiveHotel(hotelData);

          // Initialize component state
          const initialHotel = JSON.parse(JSON.stringify(hotelData));
          this.setState({
            hotel: initialHotel,
            isSyncOn: initialHotel.meta?.isSyncOn || false,
            originalHasRewardsValue: initialHotel.meta?.hasRewards || true,
            setGuestline: initialHotel.cm?.id === "guestline",
          });
        }
      } catch (err) {
        console.error("Error loading hotel data:", err);
      }
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.activeHotel !== this.props.activeHotel) {
      const hotelData = this.props.activeHotel;

      if (hotelData && Object.keys(hotelData).length > 0) {
        const initialHotel = JSON.parse(JSON.stringify(hotelData));
        this.setState({
          hotel: initialHotel,
          isSyncOn: initialHotel.meta?.isSyncOn || false,
          originalHasRewardsValue: initialHotel.meta?.hasRewards || true,
          setGuestline: initialHotel.cm?.id === "guestline",
        });
      }
    }
  }

  onHotelUpdate = (updatedHotel: any) => {
    // Create a new hotel object with all nested properties
    const hotel = {
      ...this.state.hotel,
      cm: {
        ...this.state.hotel.cm,
        channelConfig: {
          ...this.state.hotel.cm.channelConfig,
          ows: {
            ...this.state.hotel.cm.channelConfig.ows,
            SeasonnalConfig: {
              ...updatedHotel.cm.channelConfig.ows.SeasonnalConfig,
              formulas: [
                ...updatedHotel.cm.channelConfig.ows.SeasonnalConfig.formulas,
              ] as Formula[],
            },
          },
        },
      },
    };

    this.setState({ hotel });
  };

  saveConfig = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!this.state.hotel.cm.channelConfig.ows["SeasonnalConfig"]) {
      this.state.hotel.cm.channelConfig.ows["SeasonnalConfig"] = {
        formulas: [],
      };
    }

    if (this.state.hotel.cm.id !== "guestline") {
      this.setState({});
    }

    const fullName = this.state.hotel?.info?.fullName || "";
    const shortName = this.state.hotel?.info?.name || "";
    const formula = this.state.hotel?.cm?.channelConfig?.ows?.formula;
    const level = this.state.hotel?.info?.level;
    const totalRooms = this.state.hotel?.meta?.totalRooms;
    const defaultBar = this.state.hotel?.meta?.defaultBar;
    const isPublishingInventory = this.state.hotel?.meta?.isPublishingInventory;

    const fullNameValid = /^[a-zA-Z\s'&]+$/.test(fullName);
    const shortNameValid = /^[a-zA-Z\s]+$/.test(shortName);

    if (
      !fullName ||
      !formula ||
      !shortName ||
      isPublishingInventory === undefined ||
      !level ||
      level === undefined ||
      !totalRooms ||
      !defaultBar ||
      !(totalRooms > 0) ||
      !fullNameValid ||
      !shortNameValid
    ) {
      this.setState({
        validateFullName: !fullName || !fullNameValid,
        validateFullNameMsg: !fullName
          ? "Mandatory field"
          : !fullNameValid
            ? "Hotel name can only contain letters, spaces, apostrophes and ampersands"
            : "",
        validateName: !shortName || !shortNameValid,
        validateNameMsg: !shortName
          ? "Mandatory field"
          : !shortNameValid
            ? "Only alphabets and spaces allowed "
            : "",
        validateFormula: !formula,
        validateFormulaMsg: !formula ? "Mandatory field" : "",
        validateContractType: !level || level === undefined,
        validateContractTypeMsg: !level ? "Mandatory field" : "",
        validateTotalRoom: !totalRooms || !(totalRooms > 0),
        validateTotalRoomMsg: !totalRooms
          ? "Mandatory field"
          : !(totalRooms > 0)
            ? "Total rooms must be greater than 0"
            : "",
        validateDefaultBar: !defaultBar,
        validateDefaultBarMsg: !defaultBar ? "Mandatory field" : "",
      });
      toast.error("Please check highlighted field(s)");
      return;
    } else if (this.state.hotel.meta.pickupRange < 0) {
      this.setState({ validatePickupRange: true });
      this.setState({
        validatePickupRangeMsg: "Negative number not allowed",
      });
      toast.error("Please check highlighted field(s)");
      return;
    } else if (this.state.hotel.meta.otaPickupRange < 0) {
      this.setState({ validateOtaPickupRange: true });
      this.setState({
        validateOtaPickupRangeMsg: "Negative number not allowed",
      });
      toast.error("Please check highlighted field(s)");
      return;
    } else if (
      this.state.hotel.cm.APISettings.hotelId &&
      !/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(
        this.state.hotel.cm.APISettings.hotelId,
      )
    ) {
      toast.error("Please check highlighted field(s)");
      return;
    } else if (
      this.state.hotel.cm.credentials.userId &&
      !/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(
        this.state.hotel.cm.credentials.userId,
      )
    ) {
      toast.error("Please check highlighted field(s)");
      return;
    } else if (this.state.hotel.meta.totalRooms.length >= 20) {
      toast.error("Please check highlighted field(s)");
      return;
    } else if (this.state.hotel.meta.defaultBar < 0) {
      toast.error("Please check highlighted field(s)");
      return;
    } else if (this.state.hotel.meta.defaultBar > 999) {
      toast.error("Please check highlighted field(s)");
      return;
    } else {
      if (this.state.rewardsPopupToBeOpen === true) {
        this.setState({ openRewardsPopup: true });
      } else {
        this.saveConfigAfterValidation();
      }
    }
  };

  saveConfigAfterValidation = async () => {
    this.setState({ openRewardsPopup: false });

    // Create deep copies of all nested objects
    const hotel = {
      ...this.state.hotel,
      cm: {
        ...this.state.hotel.cm,
        channelConfig: {
          ...this.state.hotel.cm.channelConfig,
          ows: {
            ...this.state.hotel.cm.channelConfig.ows,
            SeasonnalConfig: {
              ...this.state.hotel.cm.channelConfig.ows.SeasonnalConfig,
              formulas:
                this.state.hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.map(
                  (formula: Formula) => ({ ...formula }),
                ),
            },
          },
        },
      },
    };

    const deleteFormula = { ...this.state.deleteFormula };

    //Reorder Override Formula
    if (hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.length > 0) {
      // Create new array with modified objects
      const updatedFormulas =
        hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.map(
          (item: Formula) => ({
            ...item,
            order: Number(item.formula.replace(/BR/g, "").replace(/\s/g, "")),
          }),
        );

      // Sort the new array
      updatedFormulas.sort(
        (a: Formula, b: Formula) =>
          parseFloat(String(b.order)) - parseFloat(String(a.order)),
      );

      // Create new SeasonnalConfig object with the updated formulas
      hotel.cm.channelConfig.ows.SeasonnalConfig = {
        ...hotel.cm.channelConfig.ows.SeasonnalConfig,
        formulas: updatedFormulas,
      };
    }

    this.setState({ hotel });
    const warningDialogOpened = this.state.displayUpdateRateWarningOnsave;

    const updateConfigPromise = toast.promise(
      YieldApi.updateHotelConfig({
        hotel: this.state.hotel,
        start: moment().format("YYYY-MM-DD"),
      }),
      {
        pending: "Saving hotel config...",
        error: "Error saving hotel config",
        success: "Hotel config saved",
      },
    );
    updateConfigPromise.catch(() => {
      return;
    });

    if (warningDialogOpened || deleteFormula.deleteLabel) {
      if (deleteFormula.deleteLabel) {
        toast.promise(
          YieldApi.bulkUpdateOwsOverride(deleteFormula).then(() => {
            this.setState({
              deleteFormula: {
                hotelId: 0,
                start: "",
                end: "",
                label: "",
                deleteLabel: "",
              },
            });
          }),
          {
            pending: "Deleting OWS Formula override...",
            error: "Error deleting OWS Formula override",
            success: "OWS Formula override deleted",
          },
        );
      } else {
        if (warningDialogOpened) {
          let formulaLabel;
          if (typeof hotel.cm.channelConfig.ows.label != "undefined") {
            if (hotel.cm.channelConfig.ows.label != "") {
              formulaLabel = hotel.cm.channelConfig.ows.label;
            } else {
              formulaLabel = "Default";
            }
          } else {
            formulaLabel = "Default";
          }
          const updateDefaultFormula = {
            hotelId: this.state.hotel.hotelId,
            start: moment().format("YYYY-MM-DD"),
            end: moment().add("2", "year").format("YYYY-MM-DD"),
            label: formulaLabel,
          };

          toast.promise(YieldApi.bulkUpdateOwsOverride(updateDefaultFormula), {
            pending: "Updating Default OWS differential...",
            error: "Error updating Default OWS differential",
            success: "Default OWS differential updated",
          });
        }
      }
    }
    this.setState({
      originalHasRewardsValue: this.state.hotel.meta.hasRewards,
    });
  };

  cancelChanges = async () => {
    this.setState({
      deleteFormula: {
        hotelId: 0,
        start: "",
        end: "",
        label: "",
        deleteLabel: "",
      },
    });

    this.setState({
      hotel: this.props.activeHotel,
      currentSetIndex: this.state.currentSetIndex + 1,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    this.setState({
      validateFullName: false,
      validateFullNameMsg: "",
      validateName: false,
      validateNameMsg: "",
      validateContractType: false,
      validateContractTypeMsg: "",
      validateTotalRoom: false,
      validateTotalRoomMsg: "",
      validateInventory: false,
      validateInventoryMsg: "",
      validateCmHotelCode: false,
      validateCmHotelCodeMsg: "",
      validatePmsHotelCode: false,
      validatePmsHotelCodeMsg: "",
      validateUserName: false,
      validateUserNameMsg: "",
      validateFelexTable: false,
      validateFelexTableMsg: "",
      validateOwsFelexTable: false,
      validateOwsFelexTableMsg: "",
    });
  };
  closewarningDialog = () => {
    const warningDialogOpened = false;
    this.setState({ warningDialogOpened });
  };

  onBlurOfInputFields = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    switch (fieldName) {
      case "fullName":
        if (!value) {
          this.setState({ validateFullName: true });
          this.setState({
            validateFullNameMsg: "Mandatory field",
          });
        } else if (!value.match(/^[a-zA-Z\s'&]+$/)) {
          this.setState({ validateFullName: true });
          this.setState({
            validateFullNameMsg:
              "Only alphabets, spaces, and following special characters allowed:  &  '",
          });
        } else {
          this.setState({ validateFullName: false });
          this.setState({ validateFullNameMsg: "" });
        }
        break;
      case "name":
        if (!value) {
          this.setState({ validateName: true });
          this.setState({ validateNameMsg: "Mandatory field" });
        } else if (!value.match(/^[a-zA-Z\s]+$/)) {
          this.setState({ validateName: true });
          this.setState({
            validateNameMsg: "Only alphabets and spaces allowed",
          });
        } else {
          this.setState({ validateName: false });
          this.setState({ validateNameMsg: "" });
        }
        break;
      case "level":
        if (!value) {
          this.setState({ validateContractType: true });
          this.setState({
            validateContractTypeMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateContractType: false });
          this.setState({ validateContractTypeMsg: "" });
        }
        break;
      case "totalRooms":
        if (!value) {
          this.setState({ validateTotalRoom: true });
          this.setState({ validateTotalRoomMsg: "Mandatory field" });
        } else if (this.state.hotel.meta.totalRooms.length >= 20) {
          this.setState({ validateTotalRoom: true });
          this.setState({ validateTotalRoomMsg: "Length Exceeded" });
        } else if (this.state.hotel.meta.totalRooms < 0) {
          this.setState({ validateTotalRoom: true });
          this.setState({
            validateTotalRoomMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateTotalRoom: false });
          this.setState({ validateTotalRoomMsg: "" });
        }
        break;
      case "isPublishingInventory":
        if (!value) {
          this.setState({ validateInventory: true });
          this.setState({ validateInventoryMsg: "Mandatory field" });
        } else {
          this.setState({ validateInventory: false });
          this.setState({ validateInventoryMsg: "" });
        }
        break;
      case "pmsProvider":
        if (!value) {
          this.setState({ validateProviderKey: true });
          this.setState({
            validateProviderKeyMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateProviderKey: false });
          this.setState({ validateProviderKeyMsg: "" });
        }
        break;
      case "pmsHotelCode":
        if (!value) {
          this.setState({ validatePmsHotelCode: false });
          this.setState({ validatePmsHotelCodeMsg: "" });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validatePmsHotelCode: true });
          this.setState({
            validatePmsHotelCodeMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validatePmsHotelCode: false });
          this.setState({ validatePmsHotelCodeMsg: "" });
        }
        break;
      case "urlEndpoint":
        if (!value) {
          this.setState({ validatePmsUrlEndpoint: false });
          this.setState({ validatePmsUrlEndpointMsg: "" });
        } else {
          this.setState({ validatePmsUrlEndpoint: false });
          this.setState({ validatePmsUrlEndpointMsg: "" });
        }
        break;
      case "OWSFormula":
        if (!value) {
          this.setState({ validateFormula: true });
          this.setState({ validateFormulaMsg: "Mandatory field" });
        } else {
          this.setState({ validateFormula: false });
          this.setState({ validateFormulaMsg: "" });
        }
        break;
      case "cmHotelCode":
        if (!value) {
          this.setState({ validateCmHotelCode: false });
          this.setState({ validateCmHotelCodeMsg: "" });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateCmHotelCode: true });
          this.setState({
            validateCmHotelCodeMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateCmHotelCode: false });
          this.setState({ validateCmHotelCodeMsg: "" });
        }
        break;
      case "cmUserId":
        if (!value) {
          this.setState({ validateUserName: false });
          this.setState({ validateUserNameMsg: "" });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateUserName: true });
          this.setState({
            validateUserNameMsg: "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateUserName: false });
          this.setState({ validateUserNameMsg: "" });
        }
        break;
      case "flexTable":
        if (!value) {
          this.setState({ validateFelexTable: true });
          this.setState({
            validateFelexTableMsg: "Mandatory field",
          });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateFelexTable: true });
          this.setState({
            validateFelexTableMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateFelexTable: false });
          this.setState({ validateFelexTableMsg: "" });
        }
        break;
      case "OWSflexTable":
        if (!value) {
          this.setState({ validateOwsFelexTable: true });
          this.setState({
            validateOwsFelexTableMsg: "Mandatory field",
          });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateOwsFelexTable: true });
          this.setState({
            validateOwsFelexTableMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateOwsFelexTable: false });
          this.setState({ validateOwsFelexTableMsg: "" });
        }
        break;
      case "defaultBar":
        if (!value) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Mandatory field",
          });
        } else if (Number(value) < 0) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Negative number not allowed",
          });
        } else if (Number(value) > 999) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg:
              "Default base rate can't be greater then 999",
          });
        } else {
          this.setState({ validateDefaultBar: false });
          this.setState({ validateDefaultBarMsg: "" });
        }
        break;
      case "pickupRange":
        if (!value) {
          this.setState({ validatePickupRange: true });
          this.setState({
            validatePickupRangeMsg: "Mandatory field",
          });
        } else if (this.state.hotel.meta.pickupRange.length >= 20) {
          this.setState({ validatePickupRange: true });
          this.setState({ validatePickupRangeMsg: "Length Exceeded" });
        } else if (this.state.hotel.meta.pickupRange < 0) {
          this.setState({ validatePickupRange: true });
          this.setState({
            validatePickupRangeMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validatePickupRange: false });
          this.setState({ validatePickupRangeMsg: "" });
        }
        break;
      case "otaPickupRange":
        if (!value) {
          this.setState({ validateOtaPickupRange: true });
          this.setState({
            validateOtaPickupRangeMsg: "Mandatory field",
          });
        } else if (this.state.hotel.meta.otaPickupRange.length >= 20) {
          this.setState({ validateOtaPickupRange: true });
          this.setState({ validateOtaPickupRangeMsg: "Length Exceeded" });
        } else if (this.state.hotel.meta.otaPickupRange < 0) {
          this.setState({ validateOtaPickupRange: true });
          this.setState({
            validateOtaPickupRangeMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateOtaPickupRange: false });
          this.setState({ validateOtaPickupRangeMsg: "" });
        }
        break;
      default:
        this.setState({ validateFullName: false });
        this.setState({ validateFullNameMsg: "" });
    }
  };

  handleChange = (event: any) => {
    const fieldName = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const hotel = this.state.hotel;
    let displayUpdateRateWarningOnsave = false;

    switch (fieldName) {
      case "seasonnalOWS":
        hotel.cm.channelConfig.ows.seasonnalFormula = value;
        hotel.cm.channelConfig.ows.SeasonnalConfig = hotel.cm.channelConfig.ows
          .SeasonnalConfig || {
          formulas: [],
        };
        break;
      case "OWSLabel":
        hotel.cm.channelConfig.ows.label = value;
        displayUpdateRateWarningOnsave = true;
        break;
      case "OWSFormula":
        hotel.cm.channelConfig.ows.formula = value;
        displayUpdateRateWarningOnsave = true;
        break;
      case "fullName":
        hotel.info.fullName = value;
        break;
      case "name":
        hotel.info.name = value;
        break;
      case "level":
        hotel.info.level = value;
        break;
      case "totalRooms":
        if (value.length >= 20) {
          this.setState({ validateTotalRoom: true });
          this.setState({ validateTotalRoomMsg: "Length Exceeded" });
        } else {
          this.setState({ validateTotalRoom: false });
          this.setState({ validateTotalRoomMsg: "" });
        }
        hotel.meta.totalRooms = value;
        break;
      case "cmUserId":
        hotel.cm.credentials.userId = value;
        break;
      case "isPublishingInventory":
        hotel.meta.isPublishingInventory = value;
        break;
      case "cmHotelCode":
        hotel.cm.APISettings.hotelId = value;
        break;
      case "pmsProvider":
        hotel.pms.id = value;
        if (value === "guestline") {
          hotel.pms.inventoryType = "live";
        }
        break;
      case "pmsHotelCode":
        hotel.pms.apiSettings.hotelCode = value;
        break;
      case "urlEndpoint":
        hotel.pms.apiSettings.urlEndpoint = value;
        break;
      case "inventoryType":
        hotel.pms.inventoryType = value;
        if (
          this.state.hotel.pms.inventoryType === "live" &&
          this.state.hotel.pms.id !== "none"
        ) {
          hotel.meta.isPublishingInventory = false;
        }
        break;
      case "pickupRange":
        hotel.meta.pickupRange = value;
        if (this.state.isSyncOn === true) {
          hotel.meta.otaPickupRange = value;
        }
        break;
      case "otaPickupRange":
        hotel.meta.otaPickupRange = value;
        break;
      case "UsingRewards": {
        const newValue = value === "true" ? true : false;
        if (
          this.state.originalHasRewardsValue !== newValue &&
          newValue === false
        ) {
          this.setState({ rewardsPopupToBeOpen: true });
        }
        hotel.meta.hasRewards = newValue;
        break;
      }
      case "isSyncOn":
        this.setState({
          isSyncOn: value,
          disableOTAPickup: value,
        });
        if (value === true) {
          this.setState({
            validateOtaPickupRange: false,
            validateOtaPickupRangeMsg: "",
          });
        }
        hotel.meta.isSyncOn = value;
        break;
      case "flexTable":
        hotel.cm.APISettings.flexTable = value;
        break;
      case "OWSflexTable":
        hotel.cm.APISettings.OWSflexTable = value;
        break;
      case "BRflexTableRate":
        hotel.cm.APISettings.BRflexTableRate = value;
        break;

      case "OWSflexTableRate":
        hotel.cm.APISettings.OWSflexTableRate = value;
        break;
      case "Currency":
        hotel.info.currencyCode = value;
        break;
      case "isRateMatrix":
        hotel.suggestionParams.isEnabled = value;
        break;
      case "defaultBar":
        hotel.meta.defaultBar = value;

        if (!value) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Mandatory field",
          });
        } else if (value < 0) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Negative number not allowed",
          });
        } else if (value > 999) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg:
              "Default base rate can't be greater then 999",
          });
        } else {
          this.setState({ validateDefaultBar: false });
          this.setState({ validateDefaultBarMsg: "" });
        }
        break;
      case "channelManager":
        hotel.cm.id = value;
        if (value === "guestline") {
          hotel.pms.id = "guestline";
          hotel.pms.inventoryType = "live";
        } else if (hotel.pms.id === "guestline") {
          hotel.pms.id = "none";
          hotel.pms.inventoryType = "manual";
        }
        break;
    }

    this.setState({ hotel, displayUpdateRateWarningOnsave });
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };

  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  handleOnCheckboxCheck = () => {
    this.setState({
      isSyncOn: !this.state.isSyncOn,
      disableOTAPickup: !this.state.disableOTAPickup,
    });
  };

  closeRewardsPopup = () => {
    this.setState({ openRewardsPopup: false });
  };

  handleTimeZone(e: { value: string }): void {
    const hotel = this.state.hotel;
    const nameValue = e.value;
    hotel.info.timezone = nameValue;

    this.setState({
      hotel,
    });
  }

  render() {
    const date = "2024-04-15";
    const onboardDate = this.props.activeHotel?.hotelOnboardDate || date;
    const a = moment(onboardDate);
    const b = moment(moment().format("YYYY-MM-DD"));
    console.log("activeHotel", this.props.activeHotel);
    console.log("hotel to render", this.state.hotel);
    const diff = b.diff(a, "days");
    const otaPickupRange = this.state.hotel?.meta?.otaPickupRange || 0;

    if (otaPickupRange > diff) {
      console.log("is greater", diff);
    } else {
      console.log("is lesss", diff);
    }

    console.log(
      "appStore.meta._user",
      appStore.meta._user.permissions.admin[0],
    );

    if (
      !this.state.hotel.suggestionParams ||
      !this.state.hotel.suggestionParams.isEnabled
    ) {
      return (
        <MuiThemeProvider theme={rtTheme}>
          <div className="content">
            <CircularProgress color="inherit" id="circular-progress" />
          </div>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider theme={rtTheme}>
          <div className="content">
            {appStore.meta._user.permissions.admin[0] !== "*" ? (
              <p
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                This page is admin locked. Please contact us should you need
                something changed.
              </p>
            ) : (
              <></>
            )}
            <SectionTitle {...{ value: "Global Settings" }} />
            <br />
            <div style={{ flexGrow: "1" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    // className="input-field"
                    style={{
                      width: "100%",
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Hotel Name"
                    variant="outlined"
                    name="fullName"
                    onChange={this.handleChange}
                    value={this.state.hotel.info.fullName}
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    error={this.state.validateFullName}
                    helperText={this.state.validateFullNameMsg}
                    inputProps={{
                      maxLength: 40,
                    }}
                    disabled={appStore.meta._user.permissions.admin[0] !== "*"}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    // className="input-field"
                    style={{
                      width: "100%",
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Hotel Short Name"
                    variant="outlined"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.hotel.info.name}
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    error={this.state.validateName}
                    helperText={this.state.validateNameMsg}
                    inputProps={{
                      maxLength: 20,
                    }}
                    disabled={appStore.meta._user.permissions.admin[0] !== "*"}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{ width: "100%" }}
                    disabled
                    fullWidth
                    id="outlined-basic"
                    label="Hotel ID"
                    variant="outlined"
                    name="hotelId"
                    onChange={this.handleChange}
                    value={this.state.hotel.hotelId}
                  />
                </Grid>

                <br />

                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <InputLabel>Contract Type</InputLabel>
                    <Select
                      value={this.state.hotel.info.level}
                      style={{
                        width: "100%",
                      }}
                      fullWidth
                      label="Contract Type"
                      variant="outlined"
                      name="level"
                      onChange={this.handleChange}
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      error={this.state.validateContractType}
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                    >
                      <MenuItem value="Pro">Pro</MenuItem>
                      <MenuItem value="Plus">Plus</MenuItem>
                      <MenuItem value="Assist">Assist</MenuItem>
                    </Select>
                    <FormHelperText>
                      {this.state.validateContractTypeMsg}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    disabled={appStore.meta._user.permissions.admin[0] !== "*"}
                    fullWidth
                    id="outlined-basic"
                    label="Total Number of Rooms"
                    variant="outlined"
                    name="totalRooms"
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.hotel.meta.totalRooms}
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    error={this.state.validateTotalRoom}
                    helperText={this.state.validateTotalRoomMsg}
                    inputProps={{
                      maxLength: 20,
                      min: 0,
                      max: 999999999999999,
                      readOnly:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? true
                          : false,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <InputLabel>Rate Matrix</InputLabel>
                    <Select
                      value={this.state.hotel.suggestionParams.isEnabled}
                      style={{
                        width: "100%",
                      }}
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                      fullWidth
                      id="outlined"
                      label="Rate Matrix"
                      variant="outlined"
                      name="isRateMatrix"
                      onChange={this.handleChange}
                    >
                      <MenuItem value="true">Enabled</MenuItem>
                      <MenuItem value="false">Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <InputLabel>Rewards Site Function</InputLabel>
                    <Select
                      value={this.state.hotel.meta.hasRewards}
                      style={{
                        width: "100%",
                      }}
                      fullWidth
                      label="Rewards Site Function"
                      variant="outlined"
                      name="UsingRewards"
                      onChange={this.handleChange}
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                    >
                      <MenuItem value="true">Enabled</MenuItem>
                      <MenuItem value="false">Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      defaultValue={this.state.hotel.info.currencyCode}
                      style={{
                        width: "100%",
                      }}
                      fullWidth
                      label="Currency"
                      variant="outlined"
                      name="Currency"
                      onChange={this.handleChange}
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                    >
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="GBP">GBP</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TimezoneSelect
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: "56px",
                        borderRadius: "4px",
                        borderColor: "#c4c4c4",
                        "&:hover": {
                          borderColor: "#000",
                        },
                        boxShadow: "none",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#666666",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: "0px",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "8px 14px",
                      }),
                    }}
                    value={this.state.hotel.info.timezone}
                    onChange={(e) => this.handleTimeZone(e)}
                  />
                </Grid>
              </Grid>
            </div>
            <br />

            <SectionTitle {...{ value: "Default Config" }} />
            <br />
            <div style={{ flexGrow: "1" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    // className="input-field"
                    style={{
                      width: "100%",
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Default Base Rate"
                    variant="outlined"
                    type="number"
                    name="defaultBar"
                    onChange={this.handleChange}
                    value={this.state.hotel.meta.defaultBar}
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    error={this.state.validateDefaultBar}
                    helperText={this.state.validateDefaultBarMsg}
                    inputProps={{
                      maxLength: 20,
                    }}
                    disabled={appStore.meta._user.permissions.admin[0] !== "*"}
                    required
                  />
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={3}>
                {this.state.hotel.cm.id === "guestline" ||
                this.state.hotel.pms.id === "hotsoft" ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      fullWidth
                      id="outlined-basic"
                      label="Default Inventory Pickup Range (in days)"
                      variant="outlined"
                      name="pickupRange"
                      type="number"
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      onChange={this.handleChange}
                      value={this.state.hotel.meta.pickupRange || 0}
                      error={this.state.validatePickupRange}
                      helperText={this.state.validatePickupRangeMsg}
                      inputProps={{
                        maxLength: 20,
                      }}
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                      required
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Default OTA Pickup Range (in days)"
                    variant="outlined"
                    name="otaPickupRange"
                    type="number"
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    onChange={this.handleChange}
                    value={this.state.hotel.meta.otaPickupRange || 0}
                    error={this.state.validateOtaPickupRange}
                    helperText={this.state.validateOtaPickupRangeMsg}
                    inputProps={{
                      maxLength: 20,
                    }}
                    disabled={
                      appStore.meta._user.permissions.admin[0] !== "*" ||
                      (this.state.hotel.meta.isSyncOn
                        ? this.state.hotel.meta.isSyncOn
                        : this.state.disableOTAPickup)
                    }
                    required
                  />
                </Grid>
                {(this.state.hotel.cm.id === "guestline" ||
                  this.state.hotel.pms.id === "hotsoft") && (
                  <div className="syncDiv">
                    <input
                      type="checkbox"
                      name="isSyncOn"
                      checked={
                        this.state.hotel.meta.isSyncOn
                          ? this.state.hotel.meta.isSyncOn
                          : this.state.isSyncOn
                      }
                      onChange={this.handleChange}
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? true
                          : false
                      }
                    />
                    <label className="synclabelBox">
                      Synchronize date ranges
                    </label>
                  </div>
                )}
              </Grid>
            </div>
            <br />

            <SectionTitle {...{ value: "Channel Manager Settings" }} />
            <br />
            <div style={{ flexGrow: "1" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <InputLabel>CM Provider</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      disabled
                      fullWidth
                      label="CM Provider"
                      variant="outlined"
                      name="channelManager"
                      onChange={this.handleChange}
                      value={this.state.hotel.cm.id}
                    >
                      <MenuItem value="siteMinder">SiteMinder</MenuItem>
                      <MenuItem value="channelRush">Channel Rush</MenuItem>
                      <MenuItem value="guestline">Guestline</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Tooltip
                    title="If enabled, Revanista will allow to publish availability to Channel Manager. If disabled, property's PMS should be sending availability to Channel Manager."
                    arrow
                    placement="right"
                  >
                    <FormControl>
                      <InputLabel>Publish Inventory</InputLabel>
                      <Select
                        value={this.state.hotel.meta.isPublishingInventory}
                        disabled={
                          (this.state.hotel.pms.inventoryType === "live" &&
                            this.state.hotel.pms.id !== "none") ||
                          this.state.hotel.cm.id === "guestline" ||
                          appStore.meta._user.permissions.admin[0] !== "*"
                        }
                        fullWidth
                        label="Publish Inventory"
                        variant="outlined"
                        name="isPublishingInventory"
                        onChange={this.handleChange}
                      >
                        <MenuItem value="true">Enabled</MenuItem>
                        <MenuItem value="false">Disabled</MenuItem>
                      </Select>
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Hotel Code"
                    variant="outlined"
                    name="cmHotelCode"
                    onChange={this.handleChange}
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    value={this.state.hotel.cm.APISettings.hotelId}
                    error={this.state.validateCmHotelCode}
                    helperText={this.state.validateCmHotelCodeMsg}
                    disabled={
                      this.state.setGuestline === true ||
                      appStore.meta._user.permissions.admin[0] !== "*"
                    }
                    inputProps={{
                      maxLength: 20,
                    }}
                    required
                  />
                </Grid>

                {this.state.setGuestline === false ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      style={{ width: "100%" }}
                      disabled
                      fullWidth
                      id="outlined-basic"
                      label="User Name"
                      variant="outlined"
                      name="cmUserId"
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      onChange={this.handleChange}
                      value={this.state.hotel.cm.credentials.userId}
                      error={this.state.validateUserName}
                      helperText={this.state.validateUserNameMsg}
                      inputProps={{ maxLength: 20 }}
                      required
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        fullWidth
                        disabled={
                          appStore.meta._user.permissions.admin[0] !== "*"
                        }
                        id="outlined-basic"
                        label="Base Rate Flex Table"
                        variant="outlined"
                        name="flexTable"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        value={this.state.hotel.cm.APISettings.flexTable}
                        error={this.state.validateFelexTable}
                        helperText={this.state.validateFelexTableMsg}
                        inputProps={{
                          maxLength: 20,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        fullWidth
                        disabled={
                          appStore.meta._user.permissions.admin[0] !== "*"
                        }
                        id="outlined-basic"
                        label="OWS Flex Table"
                        variant="outlined"
                        name="OWSflexTable"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        value={this.state.hotel.cm.APISettings.OWSflexTable}
                        error={this.state.validateOwsFelexTable}
                        helperText={this.state.validateOwsFelexTableMsg}
                        inputProps={{
                          maxLength: 20,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl>
                        <InputLabel>BR Flex Table Rate</InputLabel>
                        <Select
                          value={
                            this.state.hotel.cm.APISettings.BRflexTableRate
                          }
                          disabled={
                            appStore.meta._user.permissions.admin[0] !== "*"
                          }
                          style={{
                            width: "100%",
                          }}
                          fullWidth
                          id="outlined"
                          label="BR Flex Table Rate"
                          variant="outlined"
                          name="BRflexTableRate"
                          onChange={this.handleChange}
                        >
                          {Object.values(this.state.hotel.ratePlans).map(
                            (rate: any) => {
                              return (
                                rate.modeOfSale === "OTA" && (
                                  <MenuItem value={rate.id}>{rate.id}</MenuItem>
                                )
                              );
                            },
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl>
                        <InputLabel>OWS Flex Table Rate</InputLabel>
                        <Select
                          value={
                            this.state.hotel.cm.APISettings.OWSflexTableRate
                          }
                          disabled={
                            appStore.meta._user.permissions.admin[0] !== "*"
                          }
                          style={{
                            width: "100%",
                          }}
                          fullWidth
                          id="outlined"
                          label="OWS Flex Table Rate"
                          variant="outlined"
                          name="OWSflexTableRate"
                          onChange={this.handleChange}
                        >
                          {Object.values(this.state.hotel.ratePlans).map(
                            (rate: any) => {
                              return (
                                rate.modeOfSale === "OWS" && (
                                  <MenuItem value={rate.id}>{rate.id}</MenuItem>
                                )
                              );
                            },
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
            <br />

            <SectionTitle {...{ value: "PMS Settings" }} />
            <br />
            <div style={{ flexGrow: "1" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl>
                    <InputLabel>PMS Provider</InputLabel>
                    <Select
                      value={this.state.hotel.pms.id}
                      disabled={
                        this.state.hotel.cm.id === "guestline" ||
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                      style={{
                        width: "100%",
                      }}
                      fullWidth
                      label="PMS Provider"
                      variant="outlined"
                      name="pmsProvider"
                      onChange={this.handleChange}
                    >
                      <MenuItem value="hotsoft">HotSoft</MenuItem>
                      <MenuItem value="guestline">Guestline</MenuItem>
                      <MenuItem value="none">None</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>

                {this.state.hotel.pms.id !== "none" && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl>
                        <InputLabel>Inventory Type</InputLabel>
                        <Select
                          value={this.state.hotel.pms.inventoryType}
                          disabled={
                            this.state.hotel.cm.id === "guestline" ||
                            appStore.meta._user.permissions.admin[0] !== "*"
                          }
                          style={{
                            width: "100%",
                          }}
                          fullWidth
                          label="Inventory Type"
                          variant="outlined"
                          name="inventoryType"
                          onChange={this.handleChange}
                        >
                          <MenuItem value="manual">Manual</MenuItem>
                          <MenuItem value="live">Live</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                  </>
                )}

                {this.state.hotel.pms.id === "hotsoft" && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        fullWidth
                        disabled={
                          appStore.meta._user.permissions.admin[0] !== "*"
                        }
                        id="outlined-basic"
                        label="Hotel Code"
                        variant="outlined"
                        name="pmsHotelCode"
                        onChange={this.handleChange}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        value={this.state.hotel.pms.apiSettings.hotelCode}
                        error={this.state.validatePmsHotelCode}
                        helperText={this.state.validatePmsHotelCodeMsg}
                        inputProps={{
                          maxLength: 20,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        disabled={
                          appStore.meta._user.permissions.admin[0] !== "*"
                        }
                        fullWidth
                        id="outlined-basic"
                        label="URL Endpoint"
                        variant="outlined"
                        name="urlEndpoint"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        value={this.state.hotel.pms.apiSettings.urlEndpoint}
                        error={this.state.validatePmsUrlEndpoint}
                        helperText={this.state.validatePmsUrlEndpointMsg}
                        inputProps={{
                          maxLength: 100,
                          readOnly:
                            appStore.meta._user.permissions.admin[0] !== "*"
                              ? true
                              : false,
                        }}
                        required
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
            <br />

            <SectionTitle {...{ value: "OWS Differential Settings" }} />
            <br />
            {typeof this.state.hotel.cm.channelConfig.ows != "undefined" && (
              <div style={{ flexGrow: "1" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                      id="outlined-basic"
                      label="Default Label"
                      variant="outlined"
                      name="OWSLabel"
                      inputProps={{
                        maxLength: 30,
                      }}
                      onChange={this.handleChange}
                      value={
                        this.state.hotel.cm.channelConfig.ows.label || "Default"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      disabled={
                        appStore.meta._user.permissions.admin[0] !== "*"
                      }
                      id="outlined-basic"
                      label="Default Formula"
                      variant="outlined"
                      name="OWSFormula"
                      inputProps={{
                        maxLength: 20,
                      }}
                      onChange={this.handleChange}
                      value={this.state.hotel.cm.channelConfig.ows.formula}
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      error={this.state.validateFormula}
                      helperText={this.state.validateFormulaMsg}
                      required
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <br />
            {this.state.hotel.cm.channelConfig &&
              typeof this.state.hotel.cm.channelConfig.ows != "undefined" &&
              this.state.hotel.cm.channelConfig.ows && (
                <Fragment>
                  <div className="form-check form-check-inline">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.hotel.cm.channelConfig &&
                            this.state.hotel.cm.channelConfig.ows &&
                            typeof this.state.hotel.cm.channelConfig.ows
                              .seasonnalFormula != "undefined" &&
                            this.state.hotel.cm.channelConfig.ows
                              .seasonnalFormula
                              ? true
                              : false
                          }
                          onChange={this.handleChange}
                          name="seasonnalOWS"
                          color="primary"
                          disabled={
                            appStore.meta._user.permissions.admin[0] !== "*"
                              ? true
                              : false
                          }
                        />
                      }
                      label="OWS Overrides"
                    />
                  </div>
                </Fragment>
              )}
            {this.state.hotel.cm.channelConfig &&
              typeof this.state.hotel.cm.channelConfig.ows != "undefined" &&
              typeof this.state.hotel.cm.channelConfig.ows.seasonnalFormula !=
                "undefined" &&
              this.state.hotel.cm.channelConfig.ows.seasonnalFormula && (
                <FormulaOverides
                  hotel={this.state.hotel}
                  key={this.state.currentSetIndex}
                  onUpdate={this.onHotelUpdate}
                  deleteFormula={this.state.deleteFormula}
                  isEdited={this.state.onSave}
                  isAdmin={appStore.meta._user.permissions}
                />
              )}
            <br />
            {appStore.meta._user.permissions.admin[0] !== "*" ? (
              <div></div>
            ) : (
              <div
                style={{
                  float: "right",
                  marginBottom: "5vh",
                }}
              >
                <Button
                  onClick={this.cancelChanges}
                  color="secondary"
                  variant="contained"
                  style={{
                    backgroundColor: "#808080",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Cancel Changes
                </Button>
                <Button
                  onClick={this.saveConfig}
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: "#ce0e29",
                    color: "#ffffff",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>

          <Popup
            isPopupOpen={this.state.openRewardsPopup}
            closeOnOutsideClick={true}
            title="Disabling Rewards"
            content={[
              "Are you sure you want to disable rewards?",
              "The rewards rate plans in channel manager will not be automatically removed",
            ]}
            action={() => {
              this.saveConfigAfterValidation();
            }}
            actionName="Disable Rewards"
            cancelAction={this.closeRewardsPopup}
            cancelName="Cancel"
          />
        </MuiThemeProvider>
      );
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    activeHotel: state.hotelData.activeHotel,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setActiveHotel: (hotel: any) => dispatch(setActiveHotel(hotel)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NewHotelConfig));
