import React, { Component, Fragment } from "react";

import { CellValue } from "react-table";
import moment from "moment";
import { HotTable, HotTableProps } from "@handsontable-pro/react";
import { dom, GridSettings, renderers } from "handsontable";
import HistoryModal from "../historymodal";
import { getItems } from "./contextMenu";
import { BackendEvent, CellCoords } from "../../../../models/yield.models";
import { colWidths } from "../../yield-sheet.helpers";
import "./eventTable.css";
import { YieldApi } from "api/yield";
import { format, formatISO } from "date-fns";

interface State {
  hModalIsOpen: boolean;
  data: CellValue[][];
  backendEvent: BackendEvent[];
  dates: Date[];
  selectedDateIndex: number;
}

type CellChange = [number, number, number | string, (number | string)?];

interface EventTableProps {
  dates: Date[];
  hotel: { hotelId: string };
  ys: unknown;
}

class EventTable extends Component<EventTableProps, State> {
  state: State = {
    hModalIsOpen: false,
    data: [],
    backendEvent: [],
    dates: [],
    selectedDateIndex: 0,
  };

  private historyModalInstanceRef = React.createRef<any>();
  hotel: { hotelId: string };
  ys: unknown;

  openHistoryModal = (index: number) => {
    this.setState({
      hModalIsOpen: true,
      selectedDateIndex: index,
    });
    if (this.historyModalInstanceRef.current) {
      this.historyModalInstanceRef.current.open(
        formatISO(this.state.dates[index], { representation: "date" }),
      );
    }
  };

  eventFromCoords = (
    coords: CellCoords,
    eventList: BackendEvent[],
  ): BackendEvent | null => {
    for (const event of eventList) {
      if (coords.col - 1 === event.idx) {
        return event;
      }
    }
    return null;
  };

  handleEdit = async (changes: CellChange[]) => {
    if (!this.state.dates || !changes || changes[0][0] === 0) {
      return;
    }

    const col = changes[0][1];
    const newValue = changes[0][3] as string;

    const ts = moment(this.state.dates[col - 1].valueOf())
      .utc()
      .set({
        hour: 12,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    const event: BackendEvent = {
      hotelId: Number(this.hotel.hotelId),
      value: newValue,
      startTs: ts.valueOf(),
    };
    await YieldApi.updateCalendarEvents(event);
    await this.fetchEvent();
  };

  getDisplayValue = (eventData: CellValue[][]) => {
    let res: CellValue[][];
    res = [...new Array(3)].map(() => []);
    for (const columnsIdx in eventData) {
      for (const rowIdx in eventData[columnsIdx]) {
        res[Number(columnsIdx)][Number(rowIdx)] =
          eventData[Number(columnsIdx)][Number(rowIdx)].value;
      }
    }
    return res;
  };

  fetchEvent = () => {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.state.dates[0]) {
        return resolve();
      }
      const request = {
        hotelId: Number(this.hotel.hotelId),
        start: formatISO(this.state.dates[0], {
          representation: "date",
        }),
        end: formatISO(this.state.dates[this.state.dates.length - 1], {
          representation: "date",
        }),
      };
      try {
        const res = await YieldApi.fetchCalendarEvents(request);

        this.setState({ backendEvent: res });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  };

  computeMergedCells = (events: BackendEvent[]) => {
    if (!this.state.dates || !this.state.dates[0]) {
      return [];
    }
    const mergeCells: {
      row: number;
      col: number;
      colspan: number;
      rowspan: number;
    }[] = [];
    const firstCellDate = formatISO(this.state.dates[0], {
      representation: "date",
    });
    let i = 0;
    for (const event of events) {
      event.idx = Math.max(
        moment(event.startTs).diff(firstCellDate, "days"),
        0,
      );

      mergeCells.push({
        row: 2,
        col: event.idx + 1,
        colspan: 1,
        rowspan: 1,
      });

      events[i] = event;
      i++;
    }
    return mergeCells;
  };

  componentDidMount() {
    this.fetchEvent();
  }

  constructor(props: {
    dates: Date[];
    hotel: { hotelId: string };
    ys: unknown;
  }) {
    super(props);
    this.state.dates = props.dates;
    this.hotel = props.hotel;
    this.ys = props.ys;
  }

  private eventTableInstanceRef = React.createRef<HotTable>();

  prepareEventData(backendEvents: BackendEvent[]) {
    let data: CellValue[][];
    data = [...new Array(3)].map(() => []);
    if (this.state.dates.length === 0) {
      return data;
    }
    this.state.dates.forEach((date, idx) => {
      data[0][idx + 1] = {
        className: "strong black htDimmed",
        value: format(date, "cccc"),
      };
      data[1][idx + 1] = {
        className: " no-wrap inverse padded htDimmed",
        value: format(date, "d LLL"),
      };
      backendEvents.forEach((event) => {
        if (idx === event.idx) {
          data[2][idx + 1] = {
            value: event.value === "undefined" ? "" : event.value,
          };
        }
      });
    });

    return data;
  }

  render() {
    const merged = this.computeMergedCells(this.state.backendEvent);
    const eventData = this.prepareEventData(this.state.backendEvent);
    const displayData = this.getDisplayValue(eventData);

    const eventTableProps = {
      data: displayData,
      afterChange: this.handleEdit,

      columns: () => {
        const renderer: renderers.Base = (
          instance,
          TD,
          row,
          col,
          prop,
          value,
        ) => {
          //i need to get the instance, so context menu manipualtion are done here !
          dom.empty(TD);
          const span = document.createElement("span");

          if (col > 0) {
            const day = displayData[1][col];
            const dow = moment(day).day();
            if (dow === 4 || dow === 5) {
              TD.className =
                eventData[row][col] && eventData[row][col].className
                  ? "eventTableCell " + eventData[row][col].className
                  : "eventTableCell ";
            } else {
              TD.className =
                eventData[row][col] && eventData[row][col].className
                  ? "eventTableCell " + eventData[row][col].className
                  : "eventTableCell ";
            }
          }
          if (col === 0) {
            if (row === 1) {
              span.innerText = "Date";
              TD.className += " no-wrap strong inverse padded htDimmed";
              TD.appendChild(span);
            }
            if (row === 2) {
              span.innerText = "Notes";
              TD.className += " no-wrap strong black padded htDimmed";
              TD.appendChild(span);
            }
            return TD;
          }
          if (row === 1) {
            const button = document.createElement("button");
            button.className = "fas fa-newspaper btn-history";
            button.innerText = "";

            button.addEventListener("click", (event) => {
              this.openHistoryModal(col - 1);
              event.stopPropagation();
              event.preventDefault();
            });
            const span = document.createElement("span");
            span.innerText = value;
            TD.appendChild(span);
            TD.appendChild(button);
            TD.className += " no-wrap inverse padded htDimmed";
            return TD;
          }

          span.innerText = value;
          TD.appendChild(span);
          return TD;
        };

        return { renderer };
      },
      cells: (row?: number, col?: number): GridSettings => {
        if (row === 2 && col !== undefined && col > 0) {
          return { readOnly: false };
        } else {
          return { readOnly: true };
        }
      },
      colWidths: colWidths(this.state.dates.length),
      contextMenu: {
        items: getItems(
          this.ys,
          this.eventTableInstanceRef.current?.hotInstance,
          false,
        ),
      },

      mergeCells: merged,
      autoWrapRow: false,
      stretchH: "all",
      rowHeaders: false,
      fixedColumnsLeft: 1,
      fixedRowsTop: 1,
      viewportColumnRenderingOffset: 100,

      rowHeights: function (row: number) {
        return row === 2 ? 35 * 3 : 35;
      },
      trimColumns: true,
      renderAllRows: true,
      manualRowResize: true,
      preventOverflow: true,
    } as HotTableProps;

    if (this.state.dates.length === 0) {
      return <div></div>;
    }
    return (
      <Fragment>
        {this.state.hModalIsOpen && (
          <HistoryModal
            ref={this.historyModalInstanceRef}
            isOpen={this.state.hModalIsOpen}
            onClose={() => this.setState({ hModalIsOpen: false })}
            activeHotel={{ hotelId: Number(this.hotel.hotelId) }}
            dayDate={this.state.dates[this.state.selectedDateIndex].valueOf()}
          />
        )}
        <HotTable
          ref={this.eventTableInstanceRef}
          {...(eventTableProps as HotTableProps)}
        />
      </Fragment>
    );
  }
}
export default EventTable;
