import { BackendEvent } from "./../models/yield.models";
import {
  IExecSummaryPutrequest,
  IDeleteFileRequest,
  ILeftToSellUpdateList,
  IFetchCalendarEventsRequest,
  IBulkManualRateUpdate,
  IHotelConf,
  IOWSOverrideUpdate,
  BulkOwsOverridePayload,
  IEditSubscriptionlist,
} from "./../models/api.models";
import { API } from "aws-amplify";
import {
  IAvailabilityUpdate,
  IDayUpdate,
  ILeftToSellUpdate,
  ISuggestedBaseRateUpdate,
  IRestrictionsUpdate,
  IManualRateUpdate,
  INotify,
  IRewardsUpdate,
  IRatePlanConf,
  IRoomType,
  IDeleteRateplan,
  IDeleteRoomType,
  ISuggestionParams,
  IFileUploadLinkRequest,
  IFileUpload,
  IFileRequest,
} from "../models/api.models";
import axios from "axios";

export class YieldApi {
  static async notify(body: INotify) {
    return API.post("yield", `/notify`, { body });
  }

  static async list(
    hotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<IDayUpdate[]> {
    return API.get("yield", `/days/${hotelId}/${startDate}/${endDate}`, {});
  }

  static async publishAvailabilitiesInView(body: IDayUpdate) {
    return API.post("yield", `/publishAvailabilities`, { body });
  }

  static async getHotelConfig(hotelId: number): Promise<IHotelConf> {
    return API.get("yield", `/hotel/${hotelId}`, {});
  }

  static async getUsersHotelsMeta(): Promise<IHotelConf[]> {
    return API.get("yield", `/hotels`, {});
  }

  static async getLastCalendarDate(hotelId: number) {
    return API.get("yield", `/hotel/${hotelId}/lastcalendardate`, {});
  }

  static async publishRatesInView(body: IDayUpdate) {
    return API.post("yield", `/publishrates`, { body });
  }

  static async publishRestrictions(body: IDayUpdate) {
    return await API.post("yield", `/publishrestrictions`, { body });
  }

  static async getFirstPickupData(hotelId: number, updatedDate: number) {
    return API.get("yield", `/pickup/${hotelId}/${updatedDate}/first`, {});
  }

  static async getLastPickupData(hotelId: number, updatedDate: number) {
    return API.get("yield", `/pickup/${hotelId}/${updatedDate}/last`, {});
  }

  static async updateAvailability(availabilities: IAvailabilityUpdate[]) {
    const request = (body: IAvailabilityUpdate) =>
      API.put("yield", "/availability", { body });
    return Promise.all(availabilities.map(request));
  }

  static async fetchCalendarEvents(request: IFetchCalendarEventsRequest) {
    return API.get(
      "yield",
      `/event/${request.hotelId}/${request.start}/${request.end}`,
      {},
    );
  }

  static async updateCalendarEvents(body: BackendEvent) {
    return API.put("yield", `/event/${body.hotelId}`, { body });
  }

  static async updateLeftToSell(updates: ILeftToSellUpdate[]) {
    const request = (body: ILeftToSellUpdate) =>
      API.put("yield", `/left`, { body });
    return Promise.all(updates.map(request));
  }

  static async updateLeftToSellList(body: ILeftToSellUpdateList) {
    return API.put("yield", `/leftupdates`, { body });
  }

  static async ingestOta(body: IDayUpdate) {
    return API.post("yield", `/ingestOta`, { body });
  }

  static async acceptSuggestedRate(body: ISuggestedBaseRateUpdate) {
    return API.post("yield", `/acceptSuggestedRate`, { body });
  }

  static async removeSuggestedRate(body: ISuggestedBaseRateUpdate) {
    return API.post("yield", `/removeSuggestedRate`, { body });
  }

  static async updateSuggestedBaseRate(updates: ISuggestedBaseRateUpdate[]) {
    const request = (body: ISuggestedBaseRateUpdate) =>
      API.put("yield", `/suggestedrate`, { body });
    return Promise.all(updates.map(request));
  }

  static async writeManualRate(body: IManualRateUpdate) {
    return await API.post("yield", `/rates/manual`, { body });
  }

  static async writeRestrictions(body: IRestrictionsUpdate) {
    return await API.post("yield", `/restrictions`, { body });
  }

  static async bulkUpdateOwsOverride(body: BulkOwsOverridePayload) {
    return API.put("yield", `/rates/ows/override/async`, { body });
  }

  static async updateOwsOverride(body: BulkOwsOverridePayload) {
    return API.put("yield", `/rates/ows/override`, { body });
  }

  static async bulkUpdateRates(body: IDayUpdate) {
    return API.post("yield", `/rates/async`, {
      body,
    });
  }

  static async updateRates(body: IDayUpdate) {
    return API.post("yield", `/rates`, {
      body,
    });
  }

  static async bulkUpdateManualRates(body: IBulkManualRateUpdate) {
    return API.post("yield", `/rates/manual/async`, { body });
  }

  static async bulkUpdateRewards(body: IRewardsUpdate) {
    return await API.post("yield", `/rewards/async`, { body });
  }

  static async updateRewards(body: IRewardsUpdate) {
    return await API.post("yield", `/rewards`, { body });
  }

  static async bulkUpdateRestrictions(body: IRestrictionsUpdate) {
    return await API.post("yield", `/restrictions/async`, { body });
  }

  static async writeRateplan(body: IRatePlanConf) {
    return await API.put("yield", `/writerateplan`, { body });
  }

  static async writeRoomType(body: IRoomType) {
    return await API.put("yield", `/writeRoomType`, { body });
  }

  static async writeRtSortOrder(body: IRoomType) {
    return await API.put("yield", `/writeRtSortOrder`, { body });
  }

  static async writeRpSortOrder(body: IRoomType) {
    return await API.put("yield", `/writeRpSortOrder`, { body });
  }

  static async deleteRoomType(body: IDeleteRoomType) {
    return await API.del("yield", `/deleteRoomType/${body.hotelId}`, {
      body,
    });
  }

  static async updateHotelConfig(body: IHotelConf) {
    return await API.put("yield", `/updateHotelConfig`, { body });
  }

  static async addNewHotel(body: IHotelConf) {
    return await API.post("yield", `/addNewHotel`, { body });
  }

  static async writeSuggestionParams(body: ISuggestionParams) {
    return await API.put("yield", `/writeSuggestionParams/${body.hotelId}`, {
      body,
    });
  }

  static async deleteRateplan(body: IDeleteRateplan) {
    return await API.del("yield", `/deleterateplan/${body.hotelId}`, {
      body,
    });
  }

  static async getUploadLink(body: IFileUploadLinkRequest) {
    return API.post("yield", `/uploadlink/${body.hotelId}`, { body });
  }

  static async getDownloadLink(body: IFileRequest) {
    return API.post("yield", `/downloadlink/${body.hotelId}`, { body });
  }

  static async deleteFile(body: IDeleteFileRequest) {
    return API.post("yield", `/deleteFile/${body.hotelId}`, { body });
  }

  static async uploadFile(body: IFileUpload) {
    const opt = {
      url: body.url || "toto",
      method: "put",
      data: body.data,
      headers: {
        "Content-Type": body.contentType,
        "content-disposition": "attachment",
      },
    };
    return axios.request(opt);
  }

  static async getExecSummaryItems(hotelId: number) {
    return await API.get("yield", `/execSummaryItems/${hotelId}`, {});
  }

  static async putExecSummaryItem(body: IExecSummaryPutrequest) {
    return await API.put("yield", `/execSummaryItem/${body.hotelId}`, {
      body,
    });
  }

  static async editSubscriptionList(body: IEditSubscriptionlist) {
    return await API.post(
      "yield",
      `/users/${body.hotelId}/editSubscriptionList`,
      { body },
    );
  }
}
