import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface popupProps {
  isPopupOpen: boolean;
  title: string;
  content: React.ReactNode[];
  action: () => void;
  actionName: string;
  cancelAction?: () => void;
  cancelName?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  closeOnOutsideClick?: boolean; // Add this prop
}

class Popup extends Component<popupProps> {
  handleClose = () => {
    if (this.props.closeOnOutsideClick && this.props.cancelAction) {
      this.props.cancelAction();
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.isPopupOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={this.props.maxWidth || "md"}
        fullWidth
        PaperProps={{
          style: {
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: "black", height: "61px" }}
        >
          <span
            style={{
              color: "white",
            }}
          >
            {this.props.title}
          </span>
        </DialogTitle>
        <DialogContent
          style={{
            marginTop: "10px",
            overflow: "auto",
            maxHeight: "calc(90vh - 170px)",
          }}
        >
          {this.props.content.map((item, index) => (
            <DialogContentText
              id="alert-dialog-description"
              key={index}
              style={{ overflow: "visible" }}
            >
              {item}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          {this.props.cancelName !== undefined &&
            this.props.cancelAction !== undefined && (
              <Button
                onClick={() => this.props.cancelAction?.()}
                color="secondary"
                variant="contained"
                style={{
                  backgroundColor: "#808080",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                {this.props.cancelName}
              </Button>
            )}
          <Button
            onClick={() => {
              this.props.action();
            }}
            color="primary"
            id="primary-action"
            variant="contained"
            style={{
              backgroundColor: "#ce0e29",
              color: "#ffffff",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            {this.props.actionName}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Popup;
