import {
  IBulkManualRateUpdate,
  IEditSubscriptionlist,
  IFetchCalendarEventsRequest,
  ILeftToSellUpdate,
  IManualRateUpdate,
  IRatePlanConf,
  IRewardsUpdate,
  IRoomType,
  ISuggestedBaseRateUpdate,
} from "./../../../models/api.models";
import { YieldApi } from "../../../api/yield";
import { UserApi } from "../../../api/user";
import { OtaApi } from "../../../api/ota";
import { events as gnEvent } from "gn-shared";

const revanista: any = {
  files: {
    getUploadLink: async (
      hotelId: number,
      contentType: string,
      fileExtension: string,
      filename: string,
    ) => {
      return await YieldApi.getUploadLink({
        hotelId,
        fileExtension,
        contentType,
        filename,
      });
    },
    getDownloadLink: async (
      hotelId: number,
      filename: string,
      overiddenFileName: string,
    ) => {
      return await YieldApi.getDownloadLink({
        hotelId,
        filename,
        overiddenFileName,
      });
    },

    upload: async (url: string, contentType: string, data: string) => {
      return await YieldApi.uploadFile({ url, contentType, data });
    },

    delete: async (hotelId: number, filename: string) => {
      return await YieldApi.deleteFile({ hotelId, filename });
    },
  },
  hotel: {
    createUser: async (
      hotelIds: number[],
      userEmail: string,
      isAdmin: boolean,
    ) => {
      const res = await UserApi.createHotelUser({
        hotelIds,
        userEmail,
        isAdmin,
      });
      return res.userCreated;
    },

    deleteUser: async (hotelIds: number[], userEmail: string) => {
      return await UserApi.deleteHotelUser({ hotelIds, userEmail });
    },
    resetUserPassword: async (hotelIds: number[], userEmail: string) => {
      return await UserApi.resetUserPassword({ hotelIds, userEmail });
    },
    listUser: async (hotelId: number) => {
      return await UserApi.listHotelUser(hotelId);
    },
    updateMonthlyPopupValue: async (
      userEmail: string,
      customPermission: string,
      hotelIds: number,
    ) => {
      return await UserApi.updateMonthlyPopupValue({
        userEmail,
        customPermission,
        hotelIds,
      });
    },

    remindMeLater: async (
      userEmail: string,
      customPermission: string,
      hotelIds: number,
    ) => {
      return await UserApi.remindMeLater({
        userEmail,
        customPermission,
        hotelIds,
      });
    },
    getAttribute: async (token: string) => {
      return await UserApi.getAttribute(token);
    },
    editSubscriptionList: async (body: IEditSubscriptionlist) => {
      return await YieldApi.editSubscriptionList(body as IEditSubscriptionlist);
    },
    getCompSetList: async (hotelId: number) => {
      return await OtaApi.getOtaHotelCompList(hotelId);
    },
    putExecSummaryItem: async (item: any) => {
      return await YieldApi.putExecSummaryItem(item);
    },
    deleteRateplan: async (id: string, hotelId: number) => {
      return await YieldApi.deleteRateplan({ id, hotelId });
    },
    deleteRoomType: async (rtId: string, hotelId: number) => {
      return await YieldApi.deleteRoomType({ rtId, hotelId });
    },
    writeRtSortOrder: async (rt: any, hotelId: number) => {
      const body = rt;
      body.hotelId = hotelId;
      return await YieldApi.writeRtSortOrder(body as IRoomType);
    },
    writeRpSortOrder: async (rt: any, hotelId: number) => {
      const body = rt;
      body.hotelId = hotelId;
      return await YieldApi.writeRpSortOrder(body as IRoomType);
    },
    writeRateplan: async (rp: any, hotelId: number) => {
      const body = rp;
      body.hotelId = hotelId;
      return await YieldApi.writeRateplan(body as IRatePlanConf);
    },
  },
  yieldsheet: {
    notify: {
      event: async (hotelId: number, event: string) => {
        return await YieldApi.notify({
          hotelId,
          type: gnEvent.notificationType.event,
          event,
        });
      },
      suggestedBR: async (hotelId: number) => {
        return await YieldApi.notify({
          hotelId,
          type: gnEvent.notificationType.suggestions,
        });
      },
    },
    days: {
      bulkUpdateManualRates: async (payload: IBulkManualRateUpdate) => {
        return await YieldApi.bulkUpdateManualRates(payload);
      },
      updateLeftToSell: async (payload: ILeftToSellUpdate[], props: any) => {
        props.appStore.requests.setYield("updateLeftToSell", "fetching");
        try {
          const res = await YieldApi.updateLeftToSell(payload);
          props.appStore.requests.setYield("updateLeftToSell", "success");
          return res;
        } catch (err) {
          props.appStore.requests.setYield("updateLeftToSell", "error");
          throw err;
        }
      },
      updateSuggestedBaseRate: async (
        payload: ISuggestedBaseRateUpdate[],
        props: any,
      ) => {
        props.appStore.requests.setYield("updateSuggestedBaseRate", "fetching");
        try {
          const res = await YieldApi.updateSuggestedBaseRate(payload);
          props.appStore.requests.setYield(
            "updateSuggestedBaseRate",
            "success",
          );
          return res;
        } catch (err) {
          props.appStore.requests.setYield("updateSuggestedBaseRate", "error");
          throw err;
        }
      },
      writeManualRate: async (payload: IManualRateUpdate) => {
        return await YieldApi.writeManualRate(payload);
      },
    },
  },
};

const YieldSheetService = {
  revanista: revanista,
};

export { YieldSheetService };
